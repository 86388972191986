import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Tabs,
  Tab,
  Typography,
  TextField,
  InputAdornment,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { toast } from "react-toastify";

// ----- Local Imports -----
import theme from "../theme";
import client from "../client";

// ----- Modals & Components -----
import AddConfigModal from "../components/modals/configs/AddConfigModal";
import UpdatePolicyModal from "../components/modals/configs/UpdatePolicyModal";
import DeleteConfigModal from "../components/modals/configs/DeleteConfigModal";
import DeleteConfigFileModal from "../components/modals/configs/DeleteConfigFileModal";
import AddFileConfigModal from "../components/modals/configs/AddFileConfigModal";
import AddRecordModal from "../components/modals/configs/AddRecordModal";
import ViewListModal from "../components/common/ViewListModal";
import DeleteCustomListModal from "../components/modals/configs/DeleteCustomListModal";
import EditCustomListModal from "../components/modals/configs/EditCustomListModal";
import ImportPolicyModal from "../components/modals/configs/ImportPolicyModal";
import ExportPolicyModal from "../components/modals/configs/ExportPolicyModal";

// ----- Custom Components -----
import TestPolicyTab from "../components/configs/TestPolicyTab";
import EditPolicyTab from "../components/configs/EditPolicyTab";
import TrustMetricsPanel from "../components/configs/TrustMetricsPanel";

// ----- Custom Hooks -----
import useConfigsApi from "../hooks/useConfigsApi";
import useChatApi from "../hooks/useChatApi";

// ----- Icons -----
import {
  Close,
  ChevronLeft,
  ChevronRight,
  Search as SearchIcon,
  MoreVert,
} from "@mui/icons-material";

// Styled component for the resize handle
const ResizeHandle = styled("div")(({ theme }) => ({
  width: "5px",
  height: "100%",
  cursor: "col-resize",
  position: "absolute",
  right: 0,
  top: 0,
  backgroundColor: "transparent",
  "&:hover": {
    backgroundColor: theme.tmryk_background_color
      ? `${theme.tmryk_background_color}33`
      : "#0000001a",
  },
  zIndex: 10,
}));

function ConfigsPage() {
  // ------------------------ Hooks ------------------------
  const configsApi = useConfigsApi();
  const chatApi = useChatApi();

  // ------------------------ State & Refs ------------------------
  const [selectedConfig, setSelectedConfig] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // File editing states
  const [selectedFileName, setSelectedFileName] = useState("");
  const [selectedFileContent, setSelectedFileContent] = useState("");
  const [originalFileContent, setOriginalFileContent] = useState("");
  const [readOnly, setReadOnly] = useState(false);

  // Modal states
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleteFileModalOpen, setIsDeleteFileModalOpen] = useState(false);
  const [isAddFileModalOpen, setIsAddFileModalOpen] = useState(false);
  const [isAddRecordModalOpen, setIsAddRecordModalOpen] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [isDeleteCustomListModalOpen, setIsDeleteCustomListModalOpen] =
    useState(false);
  const [isEditCustomListModalOpen, setIsEditCustomListModalOpen] =
    useState(false);
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const [showImportModelMessage, setShowImportModelMessage] = useState(false);
  const [postModalAction, setPostModalAction] = useState(null);

  // Sidebar menu state
  const [selectedConfigId, setSelectedConfigId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedList, setSelectedList] = useState(null);

  // Left/Right sidebar open state
  const [leftSidebarOpen, setLeftSidebarOpen] = useState(true);
  const [rightSidebarOpen, setRightSidebarOpen] = useState(true);

  // Search state
  const [searchQuery, setSearchQuery] = useState("");

  // For the resizable sidebar
  const [leftSidebarWidth, setLeftSidebarWidth] = useState(300); // Changed to 300px default
  const [isResizing, setIsResizing] = useState(false);
  const [startX, setStartX] = useState(0);
  const [startWidth, setStartWidth] = useState(0);
  const sidebarRef = useRef(null);

  // Resize handlers
  const handleResizeStart = (e) => {
    setIsResizing(true);
    setStartX(e.clientX);
    setStartWidth(leftSidebarWidth);
    e.preventDefault();
  };

  const handleResizeMove = (e) => {
    if (isResizing) {
      // Calculate width change based on mouse movement
      const diff = e.clientX - startX;
      const newWidth = startWidth + diff;

      // Enforce min/max width constraints
      if (newWidth >= 250 && newWidth <= 600) {
        setLeftSidebarWidth(newWidth);
      }
    }
  };

  const handleResizeEnd = () => {
    setIsResizing(false);
  };

  // Add event listeners for resize
  useEffect(() => {
    if (isResizing) {
      document.addEventListener("mousemove", handleResizeMove);
      document.addEventListener("mouseup", handleResizeEnd);
    }

    return () => {
      document.removeEventListener("mousemove", handleResizeMove);
      document.removeEventListener("mouseup", handleResizeEnd);
    };
  }, [isResizing]);

  // ------------------------ Effects ------------------------
  useEffect(() => {
    configsApi.fetchConfigs();
  }, []);

  useEffect(() => {
    if (selectedConfig) {
      configsApi.fetchFileContents(selectedConfig.id);
      chatApi.setShowTestPolicyError({});
    } else {
      setSelectedFileName("");
      setSelectedFileContent("");
      setOriginalFileContent("");
    }
  }, [selectedConfig]);

  useEffect(() => {
    if (!isAddRecordModalOpen && postModalAction) {
      if (postModalAction.type === "view") {
        setIsViewModalOpen(true);
      } else if (postModalAction.type === "delete") {
        setIsDeleteCustomListModalOpen(true);
      } else if (postModalAction.type === "edit") {
        setIsEditCustomListModalOpen(true);
      }
      setPostModalAction(null);
    }
  }, [isAddRecordModalOpen, postModalAction]);

  useEffect(() => {
    configsApi.setError("");
    configsApi.setShowError(false);
    chatApi.setShowTestPolicyError({});
  }, [selectedConfig, selectedFileName]);

  // ------------------------ Handlers ------------------------
  const handleToggle = async (metric, newVal) => {
    if (!selectedConfig) {
      toast.error("Select a policy first.");
      return;
    }

    // Special handling for sensitive data detection metrics
    if (metric.label.includes("Sensitive Data Detection")) {
      // For sensitive data detection, we need to handle both the config key and the flow
      if (metric.configKey) {
        await configsApi.toggleConfigKey(
          selectedConfig.id,
          metric.configKey,
          newVal
        );
      }
      if (metric.flows) {
        await configsApi.toggleFlows(selectedConfig.id, metric.flows, newVal);
      }
      // Refresh the file contents after toggling
      await configsApi.fetchFileContents(selectedConfig.id);
      return;
    }

    // Normal handling for other metrics
    if (metric.style === "trustActive") {
      await configsApi.updateTrustActive(selectedConfig.id, metric.key, newVal);
    } else if (metric.style === "configKeyAndFlow") {
      if (metric.configKey) {
        await configsApi.toggleConfigKey(
          selectedConfig.id,
          metric.configKey,
          newVal
        );
      }
      if (metric.flows) {
        await configsApi.toggleFlows(selectedConfig.id, metric.flows, newVal);
      }
    } else if (metric.style === "flowOnly") {
      if (metric.flows) {
        await configsApi.toggleFlows(selectedConfig.id, metric.flows, newVal);
      }
    } else if (metric.style === "trustActiveAndFlow") {
      await configsApi.updateTrustActive(selectedConfig.id, metric.key, newVal);
      if (metric.flows) {
        await configsApi.toggleFlows(selectedConfig.id, metric.flows, newVal);
      }
    }
  };

  const handleThresholdChangeCommitted = async (thresholdKey, newVal) => {
    if (!selectedConfig) return;

    // Skip threshold updates for sensitive data detection metrics
    if (thresholdKey.includes("sensitive_data")) return;

    await configsApi.updateThreshold(selectedConfig.id, thresholdKey, newVal);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const selectFile = (fileName) => {
    setSelectedFileName(fileName);
    const content = fileName ? configsApi.filesContent[fileName] : "";
    setSelectedFileContent(content);
    setOriginalFileContent(content);
    setReadOnly(fileName === "models.yml" || fileName === "models.yaml");
  };

  const saveEditedContent = async () => {
    if (!selectedConfig || !selectedFileName) return;
    await configsApi.saveFileContent(
      selectedConfig.id,
      selectedFileName,
      selectedFileContent
    );
    setOriginalFileContent(selectedFileContent);
  };

  const handleCheckClick = async () => {
    if (!selectedConfig || !selectedFileName) return;

    // First save the content
    if (selectedFileContent !== originalFileContent) {
      await saveEditedContent();
    }

    // Then check validity
    await configsApi.checkFileValidity(selectedConfig.id, selectedFileName);
  };

  // ------------------------ Sidebar Menu ------------------------
  const handleMenuClick = (event, config) => {
    setAnchorEl(event.currentTarget);
    setSelectedConfigId(config.id);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEditClick = () => {
    const cfg = configsApi.configs.find((c) => c.id === selectedConfigId);
    setSelectedConfig(cfg);
    setIsUpdateModalOpen(true);
    handleMenuClose();
  };

  const handleDeleteClick = () => {
    const cfg = configsApi.configs.find((c) => c.id === selectedConfigId);
    setSelectedConfig(cfg);
    setIsDeleteModalOpen(true);
    handleMenuClose();
  };

  const handleExportClick = () => {
    const cfg = configsApi.configs.find((c) => c.id === selectedConfigId);
    setSelectedConfig(cfg);
    setIsExportModalOpen(true);
    handleMenuClose();
  };

  const addFilesToKnowledgeBase = async (formRef) => {
    if (!selectedConfig) {
      toast.error("Please select a Policy");
      return;
    }

    // Create a new FormData from the form
    const formData = new FormData();

    // Get all files from the form
    const fileInput = formRef.current.elements.files;
    const files = fileInput.files;

    // Identify converted files (they'll have text/markdown type but non-.md original extension)
    const hasConvertedFiles = Array.from(files).some(
      (file) => file.type === "text/markdown" && !file.name.endsWith(".md")
    );

    if (hasConvertedFiles) {
      toast.info("Processing converted files...");
    }

    // Add all files to formData
    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }

    // Add config name
    formData.append("config_name", selectedConfig.id);

    try {
      // Use the custom formData instead of the raw form
      await configsApi.addFilesToKnowledgeBase(
        selectedConfig.id,
        formRef,
        formData
      );
      setSelectedFiles([]);
    } catch (error) {
      console.error("Error uploading files:", error);
      toast.error("Failed to upload files");
    }
  };

  const fetchUpdatedList = async () => {
    try {
      const response = await client.get("/custom_suite_list");
      return response.data;
    } catch (error) {
      toast.error("Error fetching custom suites");
      return [];
    }
  };

  // Handle saving sensitive data entities
  const handleSaveSensitiveDataEntities = async (section, entities) => {
    if (!selectedConfig) return false;

    setIsLoading(true);
    try {
      await configsApi.updateSensitiveDataEntities(
        selectedConfig.id,
        section,
        entities
      );
      toast.success(`Sensitive data entities updated for ${section}`);
      await configsApi.fetchFileContents(selectedConfig.id);
      setIsLoading(false);
      return true;
    } catch (error) {
      console.error("Error saving sensitive data entities:", error);
      toast.error("Failed to update sensitive data entities");
      setIsLoading(false);
      return false;
    }
  };

  // Add this function in the ConfigsPage component before the return statement
  const isColang2xConfig = () => {
    // Check if any config file contains colang_version: 2.x
    if (!configsApi.filesContent) return false;

    // Files to check (config.yml and models.yml)
    const filesToCheck = ["config.yml", "models.yml"];

    // Regular expression to match colang_version: 2.x (with any subversion)
    const colang2Regex = /colang_version\s*:\s*2(\.\d+)?/i;

    for (const fileName of filesToCheck) {
      if (
        configsApi.filesContent[fileName] &&
        colang2Regex.test(configsApi.filesContent[fileName])
      ) {
        return true;
      }
    }

    return false;
  };

  // ------------------------ Return JSX ------------------------
  return (
    <Box
      display="flex"
      height="100vh"
      sx={{ backgroundColor: "#ffffff" }}
      onMouseMove={isResizing ? handleResizeMove : undefined}
      onMouseUp={isResizing ? handleResizeEnd : undefined}
    >
      {/* LEFT SIDEBAR */}
      {leftSidebarOpen && (
        <Box
          ref={sidebarRef}
          width={`${leftSidebarWidth}px`}
          position="relative"
          borderRight="1px solid #ccc"
          display="flex"
          flexDirection="column"
          sx={{ backgroundColor: "#ffffff" }}
        >
          <Box
            p={1}
            margin={1}
            borderBottom="5px solid #ccc"
            bgcolor={theme.tmryk_background_color}
            color="white"
            borderRadius={1}
            flexShrink={0}
          >
            <Typography variant="h6" fontWeight="500" textAlign={"center"}>
              Manage & Test Policies
            </Typography>
          </Box>
          <Box px={2} py={1} borderBottom="1px solid #ccc">
            <Box display="flex" justifyContent="space-between" gap={1}>
              <Button
                variant="contained"
                size="medium"
                onClick={() => setIsAddModalOpen(true)}
                sx={{
                  backgroundColor: theme.tmryk_background_color,
                  "&:hover": { backgroundColor: "#172E6C" },
                  color: "white",
                }}
              >
                Add Policy
              </Button>
              <Button
                variant="outlined"
                onClick={() => setIsImportModalOpen(true)}
                sx={{
                  color: theme.tmryk_background_color,
                  borderColor: theme.tmryk_background_color,
                  "&:hover": {
                    backgroundColor: "rgba(3, 26, 88, 0.04)",
                    borderColor: theme.tmryk_background_color,
                  },
                }}
              >
                Import
              </Button>
            </Box>
          </Box>

          {/* Search Bar */}
          <Box p={1} borderBottom="1px solid #ccc">
            <TextField
              fullWidth
              placeholder="Search policies..."
              size="small"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: theme.tmryk_background_color }} />
                  </InputAdornment>
                ),
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "rgba(3, 26, 88, 0.23)",
                  },
                  "&:hover fieldset": {
                    borderColor: theme.tmryk_background_color,
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: theme.tmryk_background_color,
                  },
                },
              }}
            />
          </Box>

          <Box flex={1} overflow="auto" p={1}>
            {configsApi.loading ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                mt={2}
              >
                <CircularProgress
                  sx={{ color: theme.tmryk_background_color }}
                />
              </Box>
            ) : (
              <List>
                {configsApi.configs
                  // Filter policies based on search query
                  .filter((config) =>
                    config.id.toLowerCase().includes(searchQuery.toLowerCase())
                  )
                  // Sort policies alphabetically by ID
                  .sort((a, b) => a.id.localeCompare(b.id))
                  .map((config) => (
                    <ListItem
                      key={config.id}
                      button
                      selected={
                        selectedConfig && selectedConfig.id === config.id
                      }
                      onClick={() => {
                        setSelectedConfig(config);
                        setSelectedFileName("");
                        setSelectedFileContent("");
                        chatApi.setShowTestPolicyError({});
                      }}
                      sx={{
                        borderRadius: "4px",
                        mb: 1,
                        padding: 0,
                        "&.Mui-selected": {
                          backgroundColor: `${theme.tmryk_background_color}`,
                          color: "white",
                        },
                        "&.Mui-selected:hover": {
                          backgroundColor: `${theme.tmryk_background_color}`,
                        },
                        "&:hover": {
                          backgroundColor: "rgba(3, 26, 88, 0.04)",
                        },
                      }}
                    >
                      <Checkbox
                        checked={
                          selectedConfig
                            ? selectedConfig.id === config.id
                            : false
                        }
                        onChange={() => {
                          setSelectedConfig(
                            selectedConfig && selectedConfig.id === config.id
                              ? null
                              : config
                          );
                        }}
                        sx={{
                          color:
                            selectedConfig && selectedConfig.id === config.id
                              ? "white"
                              : theme.tmryk_background_color,
                          "&.Mui-checked": { color: "white" },
                        }}
                      />
                      <ListItemText
                        primary={config.id}
                        sx={{
                          color:
                            selectedConfig && selectedConfig.id === config.id
                              ? "white"
                              : theme.tmryk_black_text_color,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      />
                      <Button
                        onClick={(event) => handleMenuClick(event, config)}
                        sx={{ minWidth: "auto" }}
                      >
                        <MoreVert
                          sx={{
                            color:
                              selectedConfig && selectedConfig.id === config.id
                                ? "white"
                                : "gray",
                          }}
                          fontSize="medium"
                        />
                      </Button>
                    </ListItem>
                  ))}
              </List>
            )}
          </Box>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            sx={{
              "& .MuiPaper-root": {
                boxShadow: `0px 2px 8px ${theme.tmryk_box_shadow}`,
                borderRadius: "4px",
              },
            }}
          >
            <MenuItem onClick={handleEditClick}>Edit</MenuItem>
            <MenuItem onClick={handleExportClick}>Export</MenuItem>
            <MenuItem
              onClick={handleDeleteClick}
              sx={{
                color: "red",
                "&:hover": { backgroundColor: "#FDECEA" },
              }}
            >
              Delete
            </MenuItem>
          </Menu>

          {/* Resize handle */}
          <ResizeHandle onMouseDown={handleResizeStart} />
        </Box>
      )}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ backgroundColor: "#ffffff" }}
      >
        <IconButton
          onClick={() => setLeftSidebarOpen(!leftSidebarOpen)}
          sx={{
            color: theme.tmryk_background_color,
            "&:hover": { backgroundColor: "rgba(3, 26, 88, 0.04)" },
          }}
        >
          {leftSidebarOpen ? <ChevronLeft /> : <ChevronRight />}
        </IconButton>
      </Box>
      <Box
        flex={1}
        display="flex"
        flexDirection="column"
        sx={{ backgroundColor: "#ffffff", overflowY: "auto" }}
      >
        <Box
          p={1}
          marginTop={1}
          marginBottom={1}
          borderBottom="5px solid #ccc"
          bgcolor={theme.tmryk_background_color}
          color="white"
          borderRadius={1}
          flexShrink={0}
        >
          <Typography variant="h6" fontWeight="500" noWrap textAlign={"center"}>
            {selectedConfig
              ? `Policy: ${selectedConfig.id}`
              : "Select A Policy"}
          </Typography>
        </Box>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          flexShrink={0}
          TabIndicatorProps={{
            style: { backgroundColor: theme.tmryk_background_color },
          }}
        >
          <Tab
            label="Test Policy"
            style={{
              color: selectedTab === 0 ? "white" : theme.tmryk_background_color,
              backgroundColor:
                selectedTab === 0 ? theme.tmryk_background_color : "inherit",
              borderRadius: "8px 8px 0 0",
              border: "1px solid gray",
              fontWeight: "500",
            }}
          />
          <Tab
            label="Advance Configuration"
            style={{
              color: selectedTab === 1 ? "white" : theme.tmryk_background_color,
              backgroundColor:
                selectedTab === 1 ? theme.tmryk_background_color : "inherit",
              borderRadius: "8px 8px 0 0",
              border: "1px solid gray",
              fontWeight: "500",
            }}
          />
        </Tabs>
        <hr />
        {selectedTab === 0 && (
          <TestPolicyTab
            showTestPolicyError={chatApi.showTestPolicyError}
            promptMessage={chatApi.promptMessage}
            setPromptMessage={chatApi.setPromptMessage}
            apiResponse={chatApi.apiResponse}
            isSubmitting={chatApi.isSubmitting}
            isGenerating={chatApi.isGenerating}
            generatedPrompts={chatApi.generatedPrompts}
            selectedGeneratedPrompt={chatApi.selectedGeneratedPrompt}
            handleGenerateClick={chatApi.generatePromptVariations}
            handleGeneratedPromptClick={chatApi.handleGeneratedPromptClick}
            handleSubmit={() => chatApi.submitPrompt(selectedConfig?.id)}
            handleClearResponse={chatApi.clearResponse}
            setIsAddRecordModalOpen={setIsAddRecordModalOpen}
          />
        )}
        {selectedTab === 1 && (
          <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, position: 'relative' }}>
            <Box 
              sx={{ 
                position: 'absolute',
                top: '10px',
                right: '10px',
                zIndex: 2
              }}
            >
              <Button
                variant="contained"
                onClick={() => setIsUpdateModalOpen(true)}
                disabled={!selectedConfig}
                sx={{
                  backgroundColor: theme.tmryk_background_color,
                  color: 'white',
                  '&:hover': { backgroundColor: '#172E6C' },
                  padding: '8px 16px',
                  fontWeight: 'medium',
                  borderRadius: '4px'
                }}
              >
                UPDATE POLICY
              </Button>
            </Box>
            <Box sx={{ pt: 5 }}>
              <EditPolicyTab
                showError={configsApi.showError}
                error={configsApi.error}
                fileNames={configsApi.fileNames}
                selectedFileName={selectedFileName}
                selectedFileContent={selectedFileContent}
                setSelectedFileContent={setSelectedFileContent}
                selectFile={selectFile}
                saveEditedContent={saveEditedContent}
                handleCheckClick={handleCheckClick}
                isChecking={configsApi.isChecking}
                setIsAddFileModalOpen={setIsAddFileModalOpen}
                setIsDeleteFileModalOpen={setIsDeleteFileModalOpen}
                readOnly={readOnly}
              />
            </Box>
          </Box>
        )}
      </Box>
      {/* Only show the sidebar toggle button if not using Colang 2.x */}
      {!isColang2xConfig() && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ backgroundColor: "#ffffff" }}
        >
          <IconButton
            onClick={() => setRightSidebarOpen(!rightSidebarOpen)}
            sx={{
              color: theme.tmryk_background_color,
              "&:hover": { backgroundColor: "rgba(3, 26, 88, 0.04)" },
            }}
          >
            {rightSidebarOpen ? <ChevronRight /> : <ChevronLeft />}
          </IconButton>
        </Box>
      )}
      {rightSidebarOpen && !isColang2xConfig() && (
        <TrustMetricsPanel
          thresholds={configsApi.thresholds}
          parsedConfig={configsApi.parsedConfig}
          runDetails={chatApi.runDetails}
          selectedConfig={selectedConfig}
          selectedFiles={selectedFiles}
          setSelectedFiles={setSelectedFiles}
          flowsPresent={configsApi.flowsPresent}
          handleToggle={handleToggle}
          handleThresholdChangeCommitted={handleThresholdChangeCommitted}
          addFilesToKnowledgeBase={addFilesToKnowledgeBase}
          onSaveSensitiveDataEntities={handleSaveSensitiveDataEntities}
        />
      )}
      {/* ---- MODALS SECTION ---- */}
      {isAddModalOpen && (
        <AddConfigModal
          isModalOpen={isAddModalOpen}
          setIsModalOpen={setIsAddModalOpen}
          onConfigAdded={async (newConfig) => {
            await configsApi.fetchConfigs();
            setSelectedConfig(newConfig);
            setIsAddModalOpen(false);
            if (!newConfig) {
              setShowImportModelMessage(true);
            }
          }}
          selectedConfig={selectedConfig}
        />
      )}
      {isUpdateModalOpen && selectedConfig && (
        <UpdatePolicyModal
          isModalOpen={isUpdateModalOpen}
          setIsModalOpen={setIsUpdateModalOpen}
          onConfigUpdated={async (updatedConfig) => {
            await configsApi.fetchConfigs();
            setSelectedConfig(updatedConfig);
            setIsUpdateModalOpen(false);
          }}
          configs={configsApi.configs}
          selectedConfig={selectedConfig}
        />
      )}
      {isDeleteModalOpen && (
        <DeleteConfigModal
          isModalOpen={isDeleteModalOpen}
          setIsModalOpen={setIsDeleteModalOpen}
          selectedConfig={selectedConfig}
          onSuccess={async () => {
            toast.success("Policy deleted successfully!");
            setSelectedConfig(null);
            await configsApi.fetchConfigs();
          }}
        />
      )}
      {isDeleteFileModalOpen && (
        <DeleteConfigFileModal
          isModalOpen={isDeleteFileModalOpen}
          setIsModalOpen={setIsDeleteFileModalOpen}
          configName={selectedConfig?.id}
          fileName={selectedFileName}
          onSuccess={() => {
            setSelectedFileName("");
            setSelectedFileContent("");
            configsApi.fetchFileContents(selectedConfig.id);
            toast.success("File deleted successfully!");
          }}
        />
      )}
      {isAddFileModalOpen && (
        <AddFileConfigModal
          isModalOpen={isAddFileModalOpen}
          setIsModalOpen={setIsAddFileModalOpen}
          configName={selectedConfig?.id}
          onSuccess={async () => {
            toast.success("Files added successfully!");
            await configsApi.fetchFileContents(selectedConfig.id);
          }}
        />
      )}
      {isAddRecordModalOpen && (
        <AddRecordModal
          isModalOpen={isAddRecordModalOpen}
          setIsModalOpen={setIsAddRecordModalOpen}
          promptMessage={chatApi.promptMessage}
          apiResponse={chatApi.apiResponse}
          openViewModal={(listName) => {
            setSelectedList(listName);
            setPostModalAction({ type: "view" });
          }}
          openDeleteModal={(listName) => {
            setSelectedList(listName);
            setPostModalAction({ type: "delete" });
          }}
          openEditModal={(listName) => {
            setSelectedList(listName);
            setPostModalAction({ type: "edit" });
          }}
        />
      )}
      {isViewModalOpen && (
        <ViewListModal
          isOpen={isViewModalOpen}
          setIsOpen={setIsViewModalOpen}
          listName={selectedList}
          setIsModalOpen={setIsAddRecordModalOpen}
        />
      )}
      {isDeleteCustomListModalOpen && (
        <DeleteCustomListModal
          isOpen={isDeleteCustomListModalOpen}
          setIsOpen={setIsDeleteCustomListModalOpen}
          listName={selectedList}
          onDeleteSuccess={() => {
            setSelectedList(null);
            toast.success("Custom list deleted successfully!");
          }}
          setParentIsOpen={setIsAddRecordModalOpen}
        />
      )}
      {isEditCustomListModalOpen && (
        <EditCustomListModal
          isOpen={isEditCustomListModalOpen}
          setIsOpen={setIsEditCustomListModalOpen}
          listName={selectedList}
          fetchUpdatedList={fetchUpdatedList}
        />
      )}
      {isExportModalOpen && (
        <ExportPolicyModal
          isModalOpen={isExportModalOpen}
          setIsModalOpen={setIsExportModalOpen}
          selectedConfig={selectedConfig}
        />
      )}
      {isImportModalOpen && (
        <ImportPolicyModal
          isModalOpen={isImportModalOpen}
          setIsModalOpen={setIsImportModalOpen}
          onImportSuccess={async () => {
            toast.success("Policy imported successfully!");
            await configsApi.fetchConfigs();
            setShowImportModelMessage(true);
          }}
        />
      )}
      {showImportModelMessage && (
        <Dialog
          open={showImportModelMessage}
          onClose={() => setShowImportModelMessage(false)}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle
            sx={{
              backgroundColor: theme.tmryk_background_color,
              color: "white",
            }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography fontWeight="500">
                Import Policy Instruction
              </Typography>
              <IconButton
                onClick={() => setShowImportModelMessage(false)}
                sx={{ color: "white" }}
              >
                <Close />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent sx={{ mt: 2 }}>
            <Typography variant="body2" sx={{ mb: 2 }}>
              Once the policy is successfully imported, please update the
              relevant model and configure the necessary API keys.
            </Typography>
          </DialogContent>
        </Dialog>
      )}
    </Box>
  );
}

export default ConfigsPage;
