import React, { useRef, useState } from "react";
import {
  Box,
  Button,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  Alert,
} from "@mui/material";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import { METRICS, METRIC_CATEGORIES } from "../../constants/metricsConfig";
import UnifiedToggle from "./UnifiedToggle";
import theme from "../../theme";
import SelectFilesButtonComponent from "../modals/configs/SelectFilesButtonComponent";
import SelectFilesListComponent from "../modals/configs/SelectFilesListComponent";
import SensitiveDataConfigModal from "../modals/configs/SensitiveDataConfigModal";
import PropTypes from "prop-types";
import {
  convertPdfToMarkdown,
  convertDocxToMarkdown,
  createFileFromText,
} from "../../utils/fileConverter";

const TrustMetricsPanel = ({
  thresholds,
  parsedConfig,
  runDetails,
  selectedConfig,
  selectedFiles,
  setSelectedFiles,
  flowsPresent,
  handleToggle,
  handleThresholdChangeCommitted,
  addFilesToKnowledgeBase,
  updateSensitiveDataEntities,
  onSaveSensitiveDataEntities,
}) => {
  const formRef = useRef(null);
  const [sensitiveDataConfigOpen, setSensitiveDataConfigOpen] = useState(false);
  const [sensitiveDataConfigType, setSensitiveDataConfigType] = useState("");
  const [currentEntities, setCurrentEntities] = useState([]);
  const [isConverting, setIsConverting] = useState(false);
  const [convertedFiles, setConvertedFiles] = useState([]);
  const [conversionError, setConversionError] = useState(null);

  const handleConfigureSensitiveData = (type) => {
    // Extract current entities from the config
    let entities = [];
    if (
      parsedConfig?.rails?.config?.sensitive_data_detection?.[
        type.toLowerCase()
      ]?.entities
    ) {
      entities =
        parsedConfig.rails.config.sensitive_data_detection[type.toLowerCase()]
          .entities;
    }

    setSensitiveDataConfigType(type.toLowerCase());
    setCurrentEntities(entities);
    setSensitiveDataConfigOpen(true);
  };

  const handleSaveSensitiveDataConfig = async (entities) => {
    if (onSaveSensitiveDataEntities) {
      const success = await onSaveSensitiveDataEntities(
        sensitiveDataConfigType,
        entities
      );
      if (success) {
        setSensitiveDataConfigOpen(false);
      }
    }
  };

  // Function to handle file conversion
  const handleFilesForConversion = async (files) => {
    setIsConverting(true);
    setConversionError(null);
    const converted = [];
    const originalFiles = [];

    try {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        if (file.name.toLowerCase().endsWith(".pdf")) {
          // Convert PDF to Markdown
          const { text, filename } = await convertPdfToMarkdown(file);
          const mdFile = createFileFromText(text, filename);
          converted.push(mdFile);
        } else if (file.name.toLowerCase().endsWith(".docx")) {
          // Convert DOCX to Markdown
          const { text, filename } = await convertDocxToMarkdown(file);
          const mdFile = createFileFromText(text, filename);
          converted.push(mdFile);
        } else {
          // Keep original file (non-PDF, non-DOCX)
          originalFiles.push(file);
        }
      }

      // Update selected files with converted files + original non-PDF/DOCX files
      setConvertedFiles(converted);
      setSelectedFiles([...originalFiles, ...converted]);

      // Clear the original file input to prevent the original files from being uploaded
      if (formRef.current && formRef.current.elements.files) {
        formRef.current.elements.files.value = "";
      }
    } catch (error) {
      console.error("File conversion error:", error);
      setConversionError(
        "Failed to convert some files. Please try again or use Markdown/text files."
      );
    } finally {
      setIsConverting(false);
    }
  };

  const handleUpload = () => {
    if (selectedFiles.length === 0) {
      return;
    }

    // Create a new FormData object directly instead of relying on the form
    const formData = new FormData();

    // Add each selected file (original non-PDF/DOCX files + converted files)
    selectedFiles.forEach((file) => {
      formData.append("files", file);
    });

    // Replace the form's files with our curated selection
    if (formRef.current) {
      // Temporarily create a DataTransfer object
      const dataTransfer = new DataTransfer();
      selectedFiles.forEach((file) => dataTransfer.items.add(file));

      // Replace the files in the form input
      if (formRef.current.elements.files) {
        formRef.current.elements.files.files = dataTransfer.files;
      }

      // Now call the addFilesToKnowledgeBase function with the updated form
      addFilesToKnowledgeBase(formRef);
    }
  };

  return (
    <Box
      paddingX={2}
      borderLeft="1px solid #ccc"
      sx={{ overflowY: "auto", backgroundColor: "#ffffff" }}
      width="400px"
    >
      <Box
        p={1}
        marginY={1}
        borderBottom="5px solid #ccc"
        bgcolor={theme.tmryk_background_color}
        color="white"
        borderRadius={1}
        flexShrink={0}
      >
        <Typography variant="h6" fontWeight="500" textAlign={"center"}>
          Trust Configuration
        </Typography>
      </Box>

      {/* Trust Score Section */}
      <Accordion sx={{ mt: 2, border: "1px solid #e0e0e0" }}>
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon sx={{ color: theme.tmryk_background_color }} />
          }
          sx={{
            backgroundColor: "#f8f9fa",
            "&:hover": { backgroundColor: "#f1f3f5" },
          }}
        >
          <Typography
            sx={{ color: theme.tmryk_black_text_color, fontWeight: "500" }}
          >
            Trust Score
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {METRICS.filter((m) =>
            METRIC_CATEGORIES.scores.includes(m.label)
          ).map((m) => {
            const threshObj = thresholds[m.key] || {};
            const isSensitiveDataDetection = m.label.includes(
              "Sensitive Data Detection"
            );
            // Only pass threshold for non-sensitive data detection metrics
            const numericValue = isSensitiveDataDetection
              ? undefined
              : threshObj.threshold !== undefined
              ? threshObj.threshold
              : 500;
            let currentScore = "N/A";

            // Only process score if the metric has a scoreKey
            if (m.scoreKey) {
              const rawScore = runDetails?.[m.scoreKey];
              currentScore =
                rawScore === -1 || rawScore === undefined || rawScore === null
                  ? "N/A"
                  : rawScore;
            }

            let toggleable = true;
            let isOn =
              m.style === "trustActive"
                ? threshObj.active ?? false
                : m.style === "flowOnly"
                ? flowsPresent(m.flows)
                : m.style === "configKeyAndFlow"
                ? parsedConfig?.rails?.config?.[m.configKey] !== undefined &&
                  flowsPresent(m.flows)
                : m.style === "trustActiveAndFlow"
                ? (threshObj.active ?? false) && flowsPresent(m.flows)
                : true;
            return (
              <UnifiedToggle
                key={m.key}
                label={m.label}
                toggleable={toggleable}
                isOn={isOn}
                onToggle={(newVal) => handleToggle(m, newVal)}
                threshold={numericValue}
                onThresholdChangeCommitted={(val) =>
                  handleThresholdChangeCommitted(m.key, val)
                }
                score={currentScore}
              />
            );
          })}
        </AccordionDetails>
      </Accordion>

      {/* Input Rails Section */}
      <Accordion sx={{ mt: 2, border: "1px solid #e0e0e0" }}>
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon sx={{ color: theme.tmryk_background_color }} />
          }
          sx={{
            backgroundColor: "#f8f9fa",
            "&:hover": { backgroundColor: "#f1f3f5" },
          }}
        >
          <Typography
            sx={{ color: theme.tmryk_black_text_color, fontWeight: "500" }}
          >
            Input Rails
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {METRICS.filter(
            (m) =>
              m.key === "jailbreak_threshold" ||
              m.key === "input_fairness_threshold" ||
              m.key === "input_moderation_threshold" ||
              m.key === "input_sensitive_data_threshold" ||
              m.key === "spell_check"
          ).map((m) => {
            const threshObj = thresholds[m.key] || {};
            const isSensitiveDataDetection = m.label.includes(
              "Sensitive Data Detection"
            );
            const isSpellCheck = m.key === "spell_check";
            // Don't pass threshold for sensitive data detection metrics or spell check
            const numericValue =
              isSensitiveDataDetection || isSpellCheck
                ? undefined
                : threshObj.threshold !== undefined
                ? threshObj.threshold
                : 500;
            let rawScore;
            let currentScore = "N/A";

            // Only process score if the metric has a scoreKey
            if (m.scoreKey) {
              switch (m.scoreKey) {
                case "input_fairness_score":
                  rawScore = runDetails?.bias_score?.input;
                  break;
                case "input_moderation_score":
                  rawScore = runDetails?.moderation_score_input;
                  break;
                default:
                  rawScore = runDetails?.[m.scoreKey];
              }
              currentScore =
                rawScore === -1 || rawScore === undefined || rawScore === null
                  ? "N/A"
                  : rawScore;
            }

            let toggleable = true;
            let isOn =
              m.style === "trustActive"
                ? threshObj.active ?? false
                : m.style === "flowOnly"
                ? flowsPresent(m.flows)
                : m.style === "configKeyAndFlow"
                ? parsedConfig?.rails?.config?.[m.configKey] !== undefined &&
                  flowsPresent(m.flows)
                : m.style === "trustActiveAndFlow"
                ? (threshObj.active ?? false) && flowsPresent(m.flows)
                : true;

            // Get current entities for sensitive data detection
            let currentSensitiveEntities = [];
            if (
              isSensitiveDataDetection &&
              parsedConfig?.rails?.config?.sensitive_data_detection?.input
                ?.entities
            ) {
              currentSensitiveEntities =
                parsedConfig.rails.config.sensitive_data_detection.input
                  .entities;
            }

            return (
              <UnifiedToggle
                key={m.key}
                label={m.label}
                toggleable={toggleable}
                isOn={isOn}
                onToggle={(newVal) => handleToggle(m, newVal)}
                threshold={numericValue}
                onThresholdChangeCommitted={(val) =>
                  handleThresholdChangeCommitted(m.key, val)
                }
                score={currentScore}
                onConfigure={
                  isSensitiveDataDetection
                    ? () => handleConfigureSensitiveData("Input")
                    : undefined
                }
              />
            );
          })}
        </AccordionDetails>
      </Accordion>

      {/* Output Rails Section */}
      <Accordion sx={{ mt: 2, border: "1px solid #e0e0e0" }}>
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon sx={{ color: theme.tmryk_background_color }} />
          }
          sx={{
            backgroundColor: "#f8f9fa",
            "&:hover": { backgroundColor: "#f1f3f5" },
          }}
        >
          <Typography
            sx={{ color: theme.tmryk_black_text_color, fontWeight: "500" }}
          >
            Output Rails
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {METRICS.filter((m) =>
            METRIC_CATEGORIES.outputRails.includes(m.label)
          ).map((m) => {
            const threshObj = thresholds[m.key] || {};
            const isSensitiveDataDetection = m.label.includes(
              "Sensitive Data Detection"
            );
            // Only pass threshold for non-sensitive data detection metrics
            const numericValue = isSensitiveDataDetection
              ? undefined
              : threshObj.threshold !== undefined
              ? threshObj.threshold
              : 500;
            let rawScore;
            let currentScore = "N/A";

            // Only process score if the metric has a scoreKey
            if (m.scoreKey) {
              switch (m.scoreKey) {
                case "output_fairness_score":
                  rawScore = runDetails?.bias_score?.output;
                  break;
                case "output_moderation_score":
                  rawScore = runDetails?.moderation_score_output;
                  break;
                default:
                  rawScore = runDetails?.[m.scoreKey];
              }
              currentScore =
                rawScore === -1 || rawScore === undefined || rawScore === null
                  ? "N/A"
                  : rawScore;
            }

            let toggleable = true;
            let isOn =
              m.style === "trustActive"
                ? threshObj.active ?? false
                : m.style === "flowOnly"
                ? flowsPresent(m.flows)
                : m.style === "configKeyAndFlow"
                ? parsedConfig?.rails?.config?.[m.configKey] !== undefined &&
                  flowsPresent(m.flows)
                : m.style === "trustActiveAndFlow"
                ? (threshObj.active ?? false) && flowsPresent(m.flows)
                : true;

            // Get current entities for sensitive data detection
            let currentSensitiveEntities = [];
            if (
              isSensitiveDataDetection &&
              parsedConfig?.rails?.config?.sensitive_data_detection?.output
                ?.entities
            ) {
              currentSensitiveEntities =
                parsedConfig.rails.config.sensitive_data_detection.output
                  .entities;
            }

            return (
              <UnifiedToggle
                key={m.key}
                label={m.label}
                toggleable={toggleable}
                isOn={isOn}
                onToggle={(newVal) => handleToggle(m, newVal)}
                threshold={numericValue}
                onThresholdChangeCommitted={(val) =>
                  handleThresholdChangeCommitted(m.key, val)
                }
                score={currentScore}
                onConfigure={
                  isSensitiveDataDetection
                    ? () => handleConfigureSensitiveData("Output")
                    : undefined
                }
              />
            );
          })}
        </AccordionDetails>
      </Accordion>

      {/* Knowledge Base Section */}
      <Accordion defaultExpanded sx={{ mt: 2, border: "1px solid #e0e0e0" }}>
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon sx={{ color: theme.tmryk_background_color }} />
          }
          sx={{
            backgroundColor: "#f8f9fa",
            "&:hover": { backgroundColor: "#f1f3f5" },
          }}
        >
          <Typography
            sx={{ color: theme.tmryk_black_text_color, fontWeight: "500" }}
          >
            Retrieval Augmented Generation
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {/* Add Sensitive Data Detection for Retrieval */}
          <UnifiedToggle
            label="Retrieval Sensitive Data Detection"
            toggleable={true}
            isOn={flowsPresent({
              retrieval: "mask sensitive data on retrieval",
            })}
            onToggle={(newVal) =>
              handleToggle(
                {
                  label: "Retrieval Sensitive Data Detection",
                  style: "flowOnly",
                  flows: { retrieval: "mask sensitive data on retrieval" },
                  configKey: "sensitive_data_detection",
                },
                newVal
              )
            }
            onConfigure={() => handleConfigureSensitiveData("Retrieval")}
          />

          <Typography
            variant="body2"
            color="textSecondary"
            sx={{ mb: 2, mt: 2 }}
          >
            <ul>
              <li>
                Upload files in Markdown, text, PDF, or DOCX format. PDF and
                DOCX files will be automatically converted to Markdown.
              </li>
              <li>Each file size should not exceed 5MB.</li>
            </ul>
          </Typography>

          {conversionError && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {conversionError}
            </Alert>
          )}

          {convertedFiles.length > 0 && (
            <Alert severity="info" sx={{ mb: 2 }}>
              {convertedFiles.length} file(s) have been converted to Markdown
            </Alert>
          )}

          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <SelectFilesButtonComponent
              selectedFiles={selectedFiles}
              setSelectedFiles={(files) => handleFilesForConversion(files)}
              acceptedFileTypes=".md,.txt,.pdf,.docx"
              ref={formRef}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleUpload}
              disabled={selectedFiles.length === 0 || isConverting}
              sx={{
                backgroundColor: theme.tmryk_background_color,
                "&:hover": { backgroundColor: "#172E6C" },
                color: "white",
              }}
            >
              {isConverting ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Upload"
              )}
            </Button>
          </Box>

          <SelectFilesListComponent selectedFiles={selectedFiles} />
        </AccordionDetails>
      </Accordion>

      {/* Detailed & General Stats Section */}
      <Box mt={2}>
        <Typography variant="h6" sx={{ color: theme.tmryk_black_text_color }}>
          Detailed & General Stats
        </Typography>
        {runDetails?.detailed_stats && (
          <Accordion sx={{ mt: 2, border: "1px solid #e0e0e0" }}>
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon sx={{ color: theme.tmryk_background_color }} />
              }
              sx={{
                backgroundColor: "#f8f9fa",
                "&:hover": { backgroundColor: "#f1f3f5" },
              }}
            >
              <Typography
                sx={{ color: theme.tmryk_black_text_color, fontWeight: "500" }}
              >
                Detailed Stats
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ whiteSpace: "pre-wrap" }}>
              {runDetails.detailed_stats}
            </AccordionDetails>
          </Accordion>
        )}
        {runDetails?.general_stats && (
          <Accordion sx={{ mt: 2, border: "1px solid #e0e0e0" }}>
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon sx={{ color: theme.tmryk_background_color }} />
              }
              sx={{
                backgroundColor: "#f8f9fa",
                "&:hover": { backgroundColor: "#f1f3f5" },
              }}
            >
              <Typography
                sx={{ color: theme.tmryk_black_text_color, fontWeight: "500" }}
              >
                General Stats
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ whiteSpace: "pre-wrap" }}>
              {runDetails.general_stats}
            </AccordionDetails>
          </Accordion>
        )}
        {!runDetails?.detailed_stats &&
          !runDetails?.general_stats &&
          !runDetails?.bot_message_log_summary && (
            <Typography
              variant="body2"
              sx={{
                marginLeft: 2,
                marginBottom: 2,
                whiteSpace: "pre-wrap",
                color: "text.secondary",
              }}
            >
              No run details available yet. Submit a prompt in the chat.
            </Typography>
          )}
      </Box>

      {/* Sensitive Data Configuration Modal */}
      {sensitiveDataConfigOpen && (
        <SensitiveDataConfigModal
          isOpen={sensitiveDataConfigOpen}
          onClose={() => setSensitiveDataConfigOpen(false)}
          configId={selectedConfig?.id}
          configType={sensitiveDataConfigType}
          currentEntities={currentEntities}
          onSave={handleSaveSensitiveDataConfig}
        />
      )}
    </Box>
  );
};

TrustMetricsPanel.propTypes = {
  thresholds: PropTypes.object.isRequired,
  parsedConfig: PropTypes.object.isRequired,
  runDetails: PropTypes.object.isRequired,
  selectedConfig: PropTypes.object,
  selectedFiles: PropTypes.array.isRequired,
  setSelectedFiles: PropTypes.func.isRequired,
  flowsPresent: PropTypes.func.isRequired,
  handleToggle: PropTypes.func.isRequired,
  handleThresholdChangeCommitted: PropTypes.func.isRequired,
  addFilesToKnowledgeBase: PropTypes.func.isRequired,
  updateSensitiveDataEntities: PropTypes.func.isRequired,
  onSaveSensitiveDataEntities: PropTypes.func,
};

export default TrustMetricsPanel;
