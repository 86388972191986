import React from "react";
import { Box, Button, Paper, Slider, Switch, Typography } from "@mui/material";
import { Settings } from "@mui/icons-material";
import theme from "../../theme";

/**
 * A reusable metric row (toggle) component.
 * Used for displaying and controlling various metrics in the Trust Metrics panel.
 */
function UnifiedToggle({
  label,
  toggleable,
  isOn,
  onToggle,
  threshold,
  onThresholdChangeCommitted,
  score,
  onConfigure,
}) {
  // Helper function to determine if score is below threshold
  const isScoreBelowThreshold = () => {
    if (score === "N/A" || threshold === undefined) return false;
    return score < threshold;
  };

  // Check if this is a sensitive data detection metric
  const isSensitiveDataDetection = label.includes("Sensitive Data Detection");

  return (
    <Paper
      sx={{
        p: 1.5,
        my: 2,
        backgroundColor: "#f8f9fa",
        boxShadow: 3,
        borderRadius: 2,
      }}
      className="hover:bg-[#ececec]"
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="subtitle2" sx={{ fontWeight: "medium" }}>
          {label}
        </Typography>
        <Box display="flex" alignItems="center">
          {isSensitiveDataDetection && isOn && (
            <Button
              size="small"
              startIcon={<Settings fontSize="small" />}
              onClick={onConfigure}
              sx={{
                mr: 1,
                color: theme.tmryk_background_color,
                "&:hover": {
                  backgroundColor: "rgba(3, 26, 88, 0.04)",
                },
              }}
            >
              Configure
            </Button>
          )}
          {toggleable && (
            <Switch
              checked={isOn}
              onChange={(e) => onToggle(e.target.checked)}
              color="primary"
              size="small"
              sx={{
                "& .MuiSwitch-switchBase.Mui-checked": {
                  color: theme.tmryk_background_color,
                  "&:hover": {
                    backgroundColor: "rgba(3, 26, 88, 0.08)",
                  },
                },
                "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                  backgroundColor: theme.tmryk_background_color,
                },
              }}
            />
          )}
        </Box>
      </Box>
      {!isSensitiveDataDetection && threshold !== undefined && (
        <Box sx={{ mt: 1 }}>
          <Slider
            value={threshold}
            step={50}
            min={0}
            max={1000}
            disabled={toggleable && !isOn}
            valueLabelDisplay="auto"
            onChangeCommitted={(e, val) => onThresholdChangeCommitted(val)}
            size="small"
            marks={Array.from({ length: 21 }, (_, i) => ({ value: i * 50 }))}
            sx={{
              color: theme.tmryk_background_color,
              "& .MuiSlider-thumb": {
                "&:hover, &.Mui-active": {
                  boxShadow: `0px 0px 0px 8px rgba(3, 26, 88, 0.16)`,
                },
              },
              "& .MuiSlider-valueLabel": {
                backgroundColor: theme.tmryk_background_color,
              },
            }}
          />
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="caption" color="textSecondary">
              Threshold: {threshold}
            </Typography>
            {score !== "N/A" && (
              <Typography variant="body2" color="textSecondary">
                <span
                  style={{
                    padding: "2px 6px",
                    backgroundColor: "#e0e0e0",
                    borderRadius: "4px",
                    fontWeight: "bold",
                    color: isScoreBelowThreshold() ? "#d32f2f" : "#2ba560",
                  }}
                >
                  Score: {score}
                </span>
              </Typography>
            )}
          </Box>
        </Box>
      )}
      {!isSensitiveDataDetection &&
        threshold === undefined &&
        score !== undefined &&
        score !== "N/A" && (
          <Typography
            variant="caption"
            color="textSecondary"
            sx={{ mt: 0.5, display: "block" }}
          >
            <span
              style={{
                padding: "2px 6px",
                backgroundColor: "#e0e0e0",
                borderRadius: "4px",
                fontWeight: "bold",
                color: isScoreBelowThreshold() ? "#d32f2f" : "inherit",
              }}
            >
              Score: {score}
            </span>
          </Typography>
        )}
    </Paper>
  );
}

export default UnifiedToggle;
