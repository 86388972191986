import React, { useEffect, useState } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Snackbar,
  Alert,
  IconButton,
  CircularProgress,
  Box,
  Tooltip,
} from "@mui/material";
import {
  Refresh as RefreshIcon,
  Stop as StopIcon,
  CheckCircle as CheckCircleIcon,
  ErrorOutline as ErrorOutlineIcon,
  Calculate as CalculateIcon, 
} from "@mui/icons-material";
import theme from "../theme";
import { LlmRunScanIcon } from "../assets/images/icons/LlmRunScanIcon/LlmRunScanIcon";
import RunScanModal from "../components/modals/llmScan/RunScanModal";
import llmScanClient from "../llmScanClient";
import client from "../client";

function LlmScanPage({ isActive, onRequestAccess }) {
  const [scans, setScans] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tenantId, setTenantId] = useState(null);
  const [username, setUserName] = useState(null);
  const [publicTenantId, setPublicTenantId] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [terminatingScans, setTerminatingScans] = useState(new Set());

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const userInfoResponse = await client.get("/user_info");
        const publicTenantIdResponse = await client.get("/public_tenant_id");
        setTenantId(userInfoResponse.data.tenantId);
        setUserName(userInfoResponse.data.username);
        setAccessToken(userInfoResponse.data.accessToken);
        setPublicTenantId(publicTenantIdResponse.data.public_tenant_id);
      } catch (error) {
        console.error("Failed to fetch user info or public tenant ID", error);
      }
    };
    fetchUserInfo();
  }, []);

  const endpoint = `/list_runs/${tenantId}?public_tenant_id=${publicTenantId}`;

  const fetchLlmScans = async () => {
    setLoading(true);
    if (tenantId !== null && publicTenantId !== null) {
      try {
        const response = await llmScanClient.get(endpoint);
        console.log("Scan response:", response.data.results); // Debug
        setScans(response.data.results);
      } catch (error) {
        console.error("Failed to fetch data", error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchLlmScans();
  }, [tenantId, publicTenantId]);

  const hasRunningScan = scans.some((scan) => scan.status === "running");

  const handleRunScanClick = () => {
    if (hasRunningScan) {
      setSnackbarMessage(
        "A scan is already running. Please wait until it finishes."
      );
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } else {
      setIsModalOpen(true);
    }
  };

  const handleRefreshClick = async () => {
    setLoading(true);
    const response = await llmScanClient.get(endpoint);
    setScans(response.data.results);
    setLoading(false);

    const newScans = response.data.results;
    const isAnyRunning = newScans.some((scan) => scan.status === "running");

    if (isAnyRunning) {
      setSnackbarMessage(
        "Your LLM scan is still running. Please check back later."
      );
      setSnackbarSeverity("info");
      setSnackbarOpen(true);
    }
  };

  const handleTerminateScan = async (scan) => {
    console.log("Terminating scan:", scan);
    try {
      setTerminatingScans((prev) => new Set([...prev, scan.run_id]));
      await llmScanClient.post(`/terminate_scan/${scan.run_id}`, {
        tenant_id: tenantId,
        username: username,
      });
      setSnackbarMessage("Scan termination requested successfully");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      await fetchLlmScans();
    } catch (error) {
      console.error("Error terminating scan:", error);
      setSnackbarMessage("Failed to terminate scan. Please try again.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setTerminatingScans((prev) => {
        const newSet = new Set(prev);
        newSet.delete(scan.run_id);
        return newSet;
      });
    }
  };

  const handleSnackbarClose = (_, reason) => {
    if (reason === "clickaway") return;
    setSnackbarOpen(false);
  };

  const getReportStatusIcon = (scan) => {
    if (scan.status === "running") return null;
    return scan.type === "failed" ? (
      <ErrorOutlineIcon sx={{ color: "#dc3545", marginRight: 1 }} />
    ) : (
      <CheckCircleIcon sx={{ color: "#28a745", marginRight: 1 }} />
    );
  };

  const getViewButtonStyle = (scan) => {
    if (scan.status === "running") return {};
    const baseStyle =
      "focus:ring-2 focus:ring-offset-2 text-sm leading-none py-3 px-5 rounded focus:outline-none ml-1 flex items-center";
    if (scan.type === "failed") {
      return `${baseStyle} focus:ring-red-500 text-white bg-red-600 hover:bg-red-700`;
    }
    return `${baseStyle} focus:ring-[#031A58] text-gray-600 bg-gray-100 hover:bg-gray-200`;
  };

  // Decide which endpoint to call based on engine type
  // * "AIPolicy" or "privateEndpoint" => call "/probes/run_tenant_scores"
  // * otherwise => call "/probes/run_public_scores"
  const handleCalculateTrustScore = async (scan) => {
    setLoading(true);
    try {
      const isPrivate =
        scan.model_type === "AIPolicy" || scan.model_type === "PrivateEndpoint";
      
      const url = isPrivate
        ? "/run_tenant_scores"
        : "/run_public_scores";

      // We'll use `client` to call guard, so the baseUrl for `client` is presumably your guard server
      const resp = await client.get(url);
      setSnackbarMessage(
        resp.data?.status
          ? `Trust Score recalculated: ${resp.data.status}`
          : "Trust Score recalculated successfully"
      );
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Calculate trust score error:", error);
      setSnackbarMessage("Failed to calculate trust score. Please try again.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex h-full">
      <div
        style={{
          width: "100vw",
          padding: "6px",
          marginTop: "180px",
          margin: "auto",
          backgroundColor: "white",
          borderRadius: 8,
        }}
        className="h-full"
      >
        <div className="flex justify-between p-2 items-center mb-2">
          <div>
            <div
              className="text-sm"
              style={{ color: theme.tmryk_black_text_color }}
            >
              View all AI Scan results here.
            </div>
          </div>
          <Button
            variant="contained"
            style={{
              backgroundColor: hasRunningScan
                ? "grey"
                : theme.tmryk_background_color,
              color: "white",
              width: "150px",
              height: "34px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={handleRunScanClick}
          >
            <LlmRunScanIcon style={{ marginRight: "16px" }} />
            Run Scan
          </Button>
        </div>
        <div
          style={{ maxHeight: "700px", minHeight: "250px", overflowY: "auto" }}
        >
          {loading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <CircularProgress />
            </Box>
          ) : (
            <Table style={{ width: "100%" }}>
              <TableHead>
                <TableRow
                  style={{
                    height: "54px",
                    maxWidth: "1122px",
                    background: theme.tmryk_background_color,
                  }}
                >
                  <TableCell
                    style={{
                      color: "white",
                      padding: "6px 80px 6px 16px",
                      borderBottom: "none",
                    }}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    style={{
                      color: "white",
                      padding: "6px 80px 6px 16px",
                      borderBottom: "none",
                    }}
                  >
                    User
                  </TableCell>
                  <TableCell
                    style={{
                      color: "white",
                      padding: "6px 80px 6px 0",
                      borderBottom: "none",
                    }}
                  >
                    Engine
                  </TableCell>
                  <TableCell
                    style={{
                      color: "white",
                      padding: "6px 80px 6px 0",
                      borderBottom: "none",
                    }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    style={{
                      color: "white",
                      padding: "6px 80px 6px 0",
                      borderBottom: "none",
                    }}
                  >
                    Test Probes
                  </TableCell>
                  <TableCell
                    style={{
                      color: "white",
                      padding: "6px 80px 6px 0",
                      borderBottom: "none",
                    }}
                  >
                    Date
                  </TableCell>
                  <TableCell
                    style={{
                      color: "white",
                      padding: "6px 32px 6px 0",
                      borderBottom: "none",
                    }}
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {scans.map((scan, index) => (
                  <TableRow
                    key={index}
                    style={{
                      height: "54px",
                      maxWidth: "1122px",
                      backgroundColor:
                        scan.type === "failed"
                          ? "rgba(220, 53, 69, 0.05)"
                          : "white",
                    }}
                  >
                    <TableCell
                      style={{
                        padding: "6px 80px 6px 16px",
                        borderBottom: "none",
                        borderRadius: "8px",
                      }}
                    >
                      {getReportStatusIcon(scan)}
                    </TableCell>
                    <TableCell
                      style={{
                        color: theme.tmryk_black_text_color,
                        padding: "6px 80px 6px 16px",
                        borderBottom: "none",
                        borderRadius: "8px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "200px",
                      }}
                    >
                      {scan.username}
                    </TableCell>
                    <TableCell
                      style={{
                        color: theme.tmryk_black_text_color,
                        padding: "6px 80px 6px 0",
                        borderBottom: "none",
                        borderRadius: "8px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "200px",
                      }}
                    >
                      {scan.model_type}
                    </TableCell>
                    <TableCell
                      style={{
                        color: theme.tmryk_black_text_color,
                        padding: "6px 80px 6px 0",
                        borderBottom: "none",
                        borderRadius: "8px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "200px",
                      }}
                    >
                      {scan.model_name}
                    </TableCell>
                    <TableCell
                      style={{
                        color: theme.tmryk_black_text_color,
                        padding: "6px 80px 6px 0",
                        borderBottom: "none",
                        borderRadius: "8px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "200px",
                      }}
                    >
                      {scan.probe}
                    </TableCell>
                    <TableCell
                      style={{
                        color: theme.tmryk_black_text_color,
                        padding: "6px 80px 6px 0",
                        borderBottom: "none",
                        borderRadius: "8px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "200px",
                      }}
                    >
                      {new Date(scan.timestamp).toLocaleString()}
                    </TableCell>
                    <TableCell
                      style={{
                        color: theme.tmryk_black_text_color,
                        padding: "6px 32px 6px 0",
                        borderBottom: "none",
                        borderRadius: "8px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "200px",
                      }}
                    >
                      {scan.status === "running" ? (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          Running
                          <Tooltip title="Refresh" arrow>
                            <IconButton
                              onClick={handleRefreshClick}
                              style={{
                                backgroundColor: theme.tmryk_background_color,
                                color: "white",
                                borderRadius: "8px",
                                marginLeft: "8px",
                                padding: "4px",
                              }}
                            >
                              <RefreshIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Stop" arrow>
                            <IconButton
                              onClick={() => handleTerminateScan(scan)}
                              disabled={terminatingScans.has(scan.run_id)}
                              style={{
                                backgroundColor: "#dc3545",
                                color: "white",
                                borderRadius: "8px",
                                marginLeft: "8px",
                                padding: "4px",
                              }}
                            >
                              {terminatingScans.has(scan.run_id) ? (
                                <CircularProgress size={24} color="inherit" />
                              ) : (
                                <StopIcon />
                              )}
                            </IconButton>
                          </Tooltip>
                        </div>
                      ) : (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {/* "View Report" Button */}
                          <a
                            href={scan.file}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <button className={getViewButtonStyle(scan)}>
                              View Report
                            </button>
                          </a>

                          {/* Show "Calculate" if probe is "generate-trust-score" */}
                          {scan.probe === "generate-trust-score" && (
                            <Tooltip title="Calculate Trust Score" arrow>
                              <IconButton
                                onClick={() => handleCalculateTrustScore(scan)}
                                style={{
                                  backgroundColor: theme.tmryk_background_color,
                                  color: "white",
                                  borderRadius: "8px",
                                  marginLeft: "8px",
                                  padding: "4px",
                                }}
                              >
                                <CalculateIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                        </div>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </div>
      </div>
      <RunScanModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        setScans={setScans}
        tenantId={tenantId}
        username={username}
        accessToken={accessToken}
        publicTenantId={publicTenantId}
        isActive={isActive}
        onRequestAccess={onRequestAccess}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default LlmScanPage;