const config = {
    API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
    LLMSCAN_API_BASE_URL: process.env.REACT_APP_LLMSCAN_API_BASE_URL,
    JAILBREAK_DETECTION_URL: process.env.REACT_APP_JAILBREAK_DETECTION_URL,
    MODERATION_URL: process.env.REACT_APP_MODERATION_URL,
    HALLUCINATION_URL: process.env.REACT_APP_HALLUCINATION_URL,
    CHATBOT_URL: process.env.REACT_APP_CHATBOT_URL
};

console.log(config)
export default config;