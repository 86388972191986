// Metric Definitions & Grouping
// For Input/Output Fairness and Hallucination Score, we use flows.
// Input Fairness: Adds/removes "check bias input" in rails.input.flows.
// Output Fairness: Adds/removes "check bias output" in rails.output.flows.
// Hallucination Score: Adds/removes "align score check facts" in rails.output.flows.

export const METRICS = [
  {
    key: "trust_score_threshold",
    label: "Trust Score",
    style: "trustActive",
    scoreKey: "trust_score",
  },
  {
    key: "jailbreak_threshold",
    label: "Jailbreak Detection",
    style: "configKeyAndFlow",
    configKey: "jailbreak_detection",
    flows: { input: "jailbreak detection heuristics" },
    scoreKey: "jailbreak_score",
  },
  {
    key: "hallucination_threshold",
    label: "Hallucination Score",
    style: "flowOnly",
    flows: { output: "alignscore check facts" },
    scoreKey: "hallucination_score",
  },
  {
    key: "input_fairness_threshold",
    label: "Input Fairness",
    style: "flowOnly",
    flows: { input: "check bias input" },
    scoreKey: "input_fairness_score",
  },
  {
    key: "output_fairness_threshold",
    label: "Output Fairness",
    style: "flowOnly",
    flows: { output: "check bias output" },
    scoreKey: "output_fairness_score",
  },
  {
    key: "input_moderation_threshold",
    label: "Input Moderation",
    style: "trustActiveAndFlow",
    flows: { input: "aegis guard check input" },
    scoreKey: "input_moderation_score",
  },
  {
    key: "output_moderation_threshold",
    label: "Output Moderation",
    style: "trustActiveAndFlow",
    flows: { output: "aegis guard check output" },
    scoreKey: "output_moderation_score",
  },
  {
    key: "input_sensitive_data_threshold",
    label: "Input Sensitive Data Detection",
    style: "configKeyAndFlow",
    configKey: "sensitive_data_detection",
    flows: { input: "mask sensitive data on input" },
  },
  {
    key: "output_sensitive_data_threshold",
    label: "Output Sensitive Data Detection",
    style: "configKeyAndFlow",
    configKey: "sensitive_data_detection",
    flows: { output: "mask sensitive data on output" },
  },
  {
    key: "spell_check",
    label: "Input Validation",
    style: "flowOnly",
    flows: { input: "spell check" },
  },
];

export const METRIC_CATEGORIES = {
  scores: ["Trust Score"],
  inputRails: ["Jailbreak Detection", "Input Fairness", "Input Moderation", "Input Sensitive Data Detection"],
  outputRails: ["Output Fairness", "Output Moderation", "Hallucination Score", "Output Sensitive Data Detection"],
}; 