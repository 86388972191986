import React, { useState } from "react";
import {
  Button,
  CssBaseline,
  TextField,
  Link,
  Grid,
  Box,
  Typography,
  Container,
  createTheme,
  ThemeProvider,
  Alert,
  InputAdornment,
  IconButton,
  OutlinedInput,
  InputLabel,
  FormControl,
  CircularProgress,
} from "@mui/material";
import tmrykLogo from "../assets/images/tmryk-logo-login.png";
import client from "../client";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const defaultTheme = createTheme();

export default function SignIn() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handlePasswordVisibility = () => setShowPassword((prev) => !prev);

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    setShowPassword(false);
    setErrorMessage("");

    const data = new FormData(event.currentTarget);
    client
      .post("/studio-login", data)
      .then((response) => {
        setIsSubmitting(false);
        if (response.status === 200) {
          const { access_token } = response.data;
          sessionStorage.setItem("showQuotaWarning", "true");
          sessionStorage.setItem("access_token", access_token);
          window.location.href = "/dashboard";
          // window.location.href = "/dashboard?login=true";
        } else {
          setErrorMessage(
            "Login failed. Please check your username and password."
          );
        }
      })
      .catch((error) => {
        setIsSubmitting(false);
        console.log(error);
        if (
          error.response &&
          error.response.status === 403 &&
          error.response.data.detail === "User account is inactive."
        ) {
          setErrorMessage(
            "This account has been deactivated. Please contact support@tumeryk.com."
          );
        } else {
          setErrorMessage(error.response?.data?.detail);
        }
        console.log("ERROR:", error.config.url, "|", error.message);
      });
  };

  return (
    <div>
      <div>
        <img
          src={tmrykLogo}
          alt="tmryk logo"
          className="w-[32rem] h-48 mx-auto mt-10"
        />
      </div>
      <ThemeProvider theme={defaultTheme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            {errorMessage && (
              <Alert severity="error" sx={{ mt: 2, width: "100%" }}>
                {errorMessage}
              </Alert>
            )}
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                autoComplete="username"
                autoFocus
                disabled={isSubmitting}
              />
              <FormControl
                margin="normal"
                fullWidth
                required
                variant="outlined"
                disabled={isSubmitting}
              >
                <InputLabel>Password</InputLabel>
                <OutlinedInput
                  required
                  fullWidth
                  label="Password"
                  name="password"
                  id="password"
                  autoComplete="current-password"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton onClick={handlePasswordVisibility} edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <Button
                disabled={isSubmitting || !username || !password}
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                {!isSubmitting ? "Sign In" : <CircularProgress size="1.5rem" />}
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="/forgot-password" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="https://tumeryk.com/sign-up" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </div>
  );
}
