import { useState } from "react";
import { toast } from "react-toastify";
import client from "../client";
import * as yamlParser from "js-yaml";

/**
 * Custom hook for managing configs API operations
 */
const useConfigsApi = () => {
  const [loading, setLoading] = useState(true);
  const [configs, setConfigs] = useState([]);
  const [fileNames, setFileNames] = useState([]);
  const [filesContent, setFilesContent] = useState({});
  const [thresholds, setThresholds] = useState({});
  const [parsedConfig, setParsedConfig] = useState({});
  const [error, setError] = useState("");
  const [showError, setShowError] = useState(false);
  const [isChecking, setIsChecking] = useState(false);

  // Fetch all configs
  const fetchConfigs = async () => {
    setLoading(true);
    try {
      const response = await client.get("/v1/rails/configs");
      setConfigs(response.data);
      return response.data;
    } catch (error) {
      console.error(error);
      toast.error("Failed to load policies.");
      return [];
    } finally {
      setLoading(false);
    }
  };

  // Fetch file contents for a specific config
  const fetchFileContents = async (configId) => {
    try {
      const response = await client.get(`/read_config?config_name=${configId}`);
      setFilesContent(response.data);
      const names = Object.keys(response.data);
      setFileNames(names);

      if (names.includes("thresholds.yml")) {
        parseThresholds(response.data["thresholds.yml"]);
      } else {
        await createDefaultThresholds(configId);
      }

      let cObj = {};
      if (names.includes("config.yml")) {
        try {
          cObj = yamlParser.load(response.data["config.yml"]) || {};
        } catch (err) {
          console.error("Error parsing config.yml", err);
        }
      }
      setParsedConfig(cObj);
      return { filesContent: response.data, fileNames: names, parsedConfig: cObj };
    } catch (err) {
      console.error("Error fetching file contents:", err);
      toast.error("Error fetching file contents");
      return { filesContent: {}, fileNames: [], parsedConfig: {} };
    }
  };

  // Parse thresholds from YAML content
  const parseThresholds = (yamlContent) => {
    try {
      const parsed = yamlParser.load(yamlContent);
      setThresholds(parsed || {});
      return parsed || {};
    } catch (err) {
      console.error("Error parsing thresholds.yml:", err);
      setThresholds({});
      return {};
    }
  };

  // Create default thresholds.yml if it doesn't exist
  const createDefaultThresholds = async (configId) => {
    const defaultThresholds = {
      trust_score_threshold: { active: true, threshold: 500 },
      jailbreak_threshold: { active: true, threshold: 500 },
      hallucination_threshold: { active: true, threshold: 500 },
      input_fairness_threshold: { threshold: 500 },
      output_fairness_threshold: { threshold: 500 },
      input_moderation_threshold: { active: false, threshold: 500 },
      output_moderation_threshold: { active: false, threshold: 500 }
    };
    const defaultContent = yamlParser.dump(defaultThresholds);
    const endpoint = `/edit_config?config_name=${configId}&file_name=thresholds.yml&content=${encodeURIComponent(
      defaultContent
    )}`;
    try {
      await client.post(endpoint);
      toast.success("Default thresholds.yml created.");
      setFilesContent((prev) => ({ ...prev, "thresholds.yml": defaultContent }));
      setFileNames((prev) => [...prev, "thresholds.yml"]);
      setThresholds(defaultThresholds);
      return defaultThresholds;
    } catch (err) {
      console.error("Failed to create thresholds.yml:", err);
      toast.error("Failed to create thresholds.yml");
      return null;
    }
  };

  // Check if flows are present in the config
  const flowsPresent = (flowsObj) => {
    if (!parsedConfig || !parsedConfig.rails) return false;
    for (const section of Object.keys(flowsObj)) {
      const flowName = flowsObj[section];
      if (
        !parsedConfig.rails[section] ||
        !Array.isArray(parsedConfig.rails[section].flows) ||
        !parsedConfig.rails[section].flows.includes(flowName)
      ) {
        return false;
      }
    }
    return true;
  };

  // Toggle flows in the config
  const toggleFlows = async (configId, flowsObj, newVal) => {
    const oldContent = filesContent["config.yml"] || "";
    let parsed;
    try {
      parsed = yamlParser.load(oldContent) || {};
    } catch (err) {
      console.error("Error parsing config.yml", err);
      return false;
    }
    if (!parsed.rails) parsed.rails = {};

    // Special handling for sensitive data detection flows
    const sensitiveDataInputFlow = "mask sensitive data on input";
    const sensitiveDataOutputFlow = "mask sensitive data on output";
    const sensitiveDataRetrievalFlow = "mask sensitive data on retrieval";
    
    const hasSensitiveDataInputFlow = flowsObj.input === sensitiveDataInputFlow;
    const hasSensitiveDataOutputFlow = flowsObj.output === sensitiveDataOutputFlow;
    const hasSensitiveDataRetrievalFlow = flowsObj.retrieval === sensitiveDataRetrievalFlow;
    
    // If toggling sensitive data detection flows for input, output, or retrieval, handle the config.sensitive_data_detection section
    if (hasSensitiveDataInputFlow || hasSensitiveDataOutputFlow || hasSensitiveDataRetrievalFlow) {
      // If enabling a sensitive data flow, make sure the config section exists
      if (newVal) {
        // If the sensitive_data_detection config doesn't exist, create it
        if (!parsed.rails.config) parsed.rails.config = {};
        if (!parsed.rails.config.sensitive_data_detection) {
          await toggleSensitiveDataDetection(configId, parsed, true);
          // Reload the updated content
          const result = await fetchFileContents(configId);
          parsed = result.parsedConfig;
        }
      }
      
      // If disabling all sensitive data flows, remove the config section
      if (!newVal) {
        const otherFlowsPresent = 
          (hasSensitiveDataInputFlow 
            ? (flowsPresent({ output: sensitiveDataOutputFlow }) || flowsPresent({ retrieval: sensitiveDataRetrievalFlow }))
            : (hasSensitiveDataOutputFlow 
              ? (flowsPresent({ input: sensitiveDataInputFlow }) || flowsPresent({ retrieval: sensitiveDataRetrievalFlow }))
              : (flowsPresent({ input: sensitiveDataInputFlow }) || flowsPresent({ output: sensitiveDataOutputFlow }))));
          
        if (!otherFlowsPresent) {
          // If no other sensitive data flow is present, remove the config section
          if (parsed.rails.config && parsed.rails.config.sensitive_data_detection) {
            await toggleSensitiveDataDetection(configId, parsed, false);
            // Reload the updated content
            const result = await fetchFileContents(configId);
            parsed = result.parsedConfig;
          }
        }
      }
    }
    
    // Handle all flows including retrieval
    for (const section of Object.keys(flowsObj)) {
      const flowName = flowsObj[section];
      if (!parsed.rails[section]) parsed.rails[section] = {};
      if (!Array.isArray(parsed.rails[section].flows)) {
        parsed.rails[section].flows = [];
      }
      if (newVal) {
        if (!parsed.rails[section].flows.includes(flowName)) {
          parsed.rails[section].flows.push(flowName);
        }
      } else {
        parsed.rails[section].flows = parsed.rails[section].flows.filter((f) => f !== flowName);
      }
    }
    const newYaml = yamlParser.dump(parsed);
    const endpoint = `/edit_config?config_name=${configId}&file_name=config.yml&content=${encodeURIComponent(
      newYaml
    )}`;
    try {
      await client.post(endpoint);
      toast.success(`Flows are now ${newVal ? "added" : "removed"}`);
      setFilesContent((prev) => ({ ...prev, "config.yml": newYaml }));
      setParsedConfig(parsed);
      return true;
    } catch (err) {
      console.error("Failed to toggle flows", err);
      return false;
    }
  };

  // Update trust active in thresholds.yml
  const updateTrustActive = async (configId, thresholdKey, newActive) => {
    if (!fileNames.includes("thresholds.yml")) return false;
    const oldContent = filesContent["thresholds.yml"] || "";
    let parsed;
    try {
      parsed = yamlParser.load(oldContent) || {};
    } catch (err) {
      console.error("Error parsing thresholds.yml", err);
      return false;
    }
    if (!parsed[thresholdKey]) {
      parsed[thresholdKey] = { active: newActive, threshold: 500 };
    } else {
      parsed[thresholdKey].active = newActive;
    }
    const newYaml = yamlParser.dump(parsed);
    const endpoint = `/edit_config?config_name=${configId}&file_name=thresholds.yml&content=${encodeURIComponent(
      newYaml
    )}`;
    try {
      await client.post(endpoint);
      toast.success(`${thresholdKey} is now ${newActive ? "On" : "Off"}`);
      setFilesContent((p) => ({ ...p, "thresholds.yml": newYaml }));
      setThresholds(parsed);
      return true;
    } catch (err) {
      console.error("Failed to update thresholds.yml active", err);
      toast.error("Failed to update thresholds.yml (active).");
      return false;
    }
  };

  // Toggle config key in config.yml
  const toggleConfigKey = async (configId, configKey, newVal) => {
    const oldContent = filesContent["config.yml"] || "";
    let parsed;
    try {
      parsed = yamlParser.load(oldContent) || {};
    } catch (err) {
      console.error("Error parsing config.yml", err);
      toast.error("Error parsing config.yml");
      return false;
    }
    if (!parsed.rails) parsed.rails = {};
    if (!parsed.rails.config) parsed.rails.config = {};

    if (newVal) {
      parsed.rails.config[configKey] = parsed.rails.config[configKey] || {};
      
      // Special handling for sensitive data detection
      if (configKey === "sensitive_data_detection") {
        return await toggleSensitiveDataDetection(configId, parsed, newVal);
      }
    } else {
      if (parsed.rails.config[configKey]) {
        delete parsed.rails.config[configKey];
        
        // Special handling for sensitive data detection
        if (configKey === "sensitive_data_detection") {
          return await toggleSensitiveDataDetection(configId, parsed, newVal);
        }
      }
    }
    const newYaml = yamlParser.dump(parsed);
    const endpoint = `/edit_config?config_name=${configId}&file_name=config.yml&content=${encodeURIComponent(
      newYaml
    )}`;
    try {
      await client.post(endpoint);
      toast.success(`${configKey} is now ${newVal ? "Enabled" : "Disabled"}`);
      setFilesContent((prev) => ({ ...prev, "config.yml": newYaml }));
      setParsedConfig(parsed);
      return true;
    } catch (err) {
      console.error(`Failed to update config.yml for ${configKey}`, err);
      toast.error("Failed to update config.yml");
      return false;
    }
  };

  // Toggle sensitive data detection configuration
  const toggleSensitiveDataDetection = async (configId, parsed, newVal) => {
    if (newVal) {
      // Add sensitive data detection configuration
      parsed.rails.config.sensitive_data_detection = {
        sensitive_data_protection_endpoint: "http://moderation.tmryk.com:8006",
        input: {
          entities: [
            // Global entities
            "CREDIT_CARD",
            "CRYPTO",
            "DATE_TIME",
            "EMAIL_ADDRESS",
            "IBAN_CODE",
            "IP_ADDRESS",
            "NRP",
            "LOCATION",
            "PERSON",
            "PHONE_NUMBER",
            "MEDICAL_LICENSE",
            "URL",
            // US-specific entities
            "US_BANK_NUMBER",
            "US_DRIVER_LICENSE",
            "US_ITIN",
            "US_PASSPORT",
            "US_SSN"
          ]
        },
        output: {
          entities: [
            // Global entities
            "CREDIT_CARD",
            "CRYPTO",
            "DATE_TIME",
            "EMAIL_ADDRESS",
            "IBAN_CODE",
            "IP_ADDRESS",
            "NRP",
            "LOCATION",
            "PERSON",
            "PHONE_NUMBER",
            "MEDICAL_LICENSE",
            "URL",
            // US-specific entities
            "US_BANK_NUMBER",
            "US_DRIVER_LICENSE",
            "US_ITIN",
            "US_PASSPORT",
            "US_SSN"
          ]
        },
        retrieval: {
          entities: [
            // Global entities
            "CREDIT_CARD",
            "CRYPTO",
            "DATE_TIME",
            "EMAIL_ADDRESS",
            "IBAN_CODE",
            "IP_ADDRESS",
            "NRP",
            "LOCATION",
            "PERSON",
            "PHONE_NUMBER",
            "MEDICAL_LICENSE",
            "URL",
            // US-specific entities
            "US_BANK_NUMBER",
            "US_DRIVER_LICENSE",
            "US_ITIN",
            "US_PASSPORT",
            "US_SSN"
          ]
        }
      };
    } else {
      // Remove sensitive data detection configuration
      if (parsed.rails.config.sensitive_data_detection) {
        delete parsed.rails.config.sensitive_data_detection;
      }
    }

    const newYaml = yamlParser.dump(parsed);
    const endpoint = `/edit_config?config_name=${configId}&file_name=config.yml&content=${encodeURIComponent(
      newYaml
    )}`;
    try {
      await client.post(endpoint);
      toast.success(`Sensitive Data Detection is now ${newVal ? "Enabled" : "Disabled"}`);
      setFilesContent((prev) => ({ ...prev, "config.yml": newYaml }));
      setParsedConfig(parsed);
      return true;
    } catch (err) {
      console.error(`Failed to update config.yml for Sensitive Data Detection`, err);
      toast.error("Failed to update config.yml");
      return false;
    }
  };

  // Update sensitive data detection entities
  const updateSensitiveDataEntities = async (configId, section, entities) => {
    const oldContent = filesContent["config.yml"] || "";
    let parsed;
    try {
      parsed = yamlParser.load(oldContent) || {};
    } catch (err) {
      console.error("Error parsing config.yml", err);
      toast.error("Error parsing config.yml");
      return false;
    }

    if (!parsed.rails) parsed.rails = {};
    if (!parsed.rails.config) parsed.rails.config = {};
    if (!parsed.rails.config.sensitive_data_detection) {
      // Create default sensitive data detection configuration with DATE_TIME included for all sections
      const defaultEntities = [
        "CREDIT_CARD", "CRYPTO", "DATE_TIME", "EMAIL_ADDRESS", "IBAN_CODE", "IP_ADDRESS", 
        "NRP", "LOCATION", "PERSON", "PHONE_NUMBER", "MEDICAL_LICENSE", "URL",
        "US_BANK_NUMBER", "US_DRIVER_LICENSE", "US_ITIN", "US_PASSPORT", "US_SSN"
      ];
      
      parsed.rails.config.sensitive_data_detection = {
        sensitive_data_protection_endpoint: "http://moderation.tmryk.com:8006",
        input: { entities: defaultEntities },
        output: { entities: defaultEntities },
        retrieval: { entities: defaultEntities }
      };
    }

    // Update the entities for the specified section
    parsed.rails.config.sensitive_data_detection[section] = { entities };

    const newYaml = yamlParser.dump(parsed);
    const endpoint = `/edit_config?config_name=${configId}&file_name=config.yml&content=${encodeURIComponent(
      newYaml
    )}`;
    try {
      await client.post(endpoint);
      toast.success(`Sensitive data entities updated for ${section}`);
      setFilesContent((prev) => ({ ...prev, "config.yml": newYaml }));
      setParsedConfig(parsed);
      return true;
    } catch (err) {
      console.error(`Failed to update sensitive data entities for ${section}`, err);
      toast.error("Failed to update sensitive data entities");
      return false;
    }
  };

  // Update threshold value in thresholds.yml
  const updateThreshold = async (configId, thresholdKey, newVal) => {
    if (!fileNames.includes("thresholds.yml")) return false;
    const oldContent = filesContent["thresholds.yml"] || "";
    let parsed;
    try {
      parsed = yamlParser.load(oldContent) || {};
    } catch (err) {
      console.error("Error parsing thresholds.yml:", err);
      return false;
    }
    if (!parsed[thresholdKey]) {
      parsed[thresholdKey] = { threshold: newVal };
    } else {
      parsed[thresholdKey].threshold = newVal;
    }
    const newYaml = yamlParser.dump(parsed);
    const endpoint = `/edit_config?config_name=${configId}&file_name=thresholds.yml&content=${encodeURIComponent(
      newYaml
    )}`;
    try {
      await client.post(endpoint);
      toast.success(`Updated ${thresholdKey} threshold to ${newVal}`);
      setFilesContent((prev) => ({ ...prev, "thresholds.yml": newYaml }));
      setThresholds(parsed);
      return true;
    } catch (err) {
      console.error("Failed to update threshold", err);
      toast.error("Failed to update threshold in thresholds.yml");
      return false;
    }
  };

  // Save edited file content
  const saveFileContent = async (configId, fileName, content) => {
    if (!configId || !fileName) return false;
    const endpoint = `/edit_config?config_name=${configId}&file_name=${encodeURIComponent(
      fileName
    )}&content=${encodeURIComponent(content)}`;
    try {
      await client.post(endpoint);
      toast.success("File content updated successfully!");
      setFilesContent((prev) => ({ ...prev, [fileName]: content }));
      
      // Update parsedConfig if config.yml was edited
      if (fileName === "config.yml") {
        try {
          const parsed = yamlParser.load(content) || {};
          setParsedConfig(parsed);
        } catch (err) {
          console.error("Error parsing updated config.yml", err);
        }
      }
      
      // Update thresholds if thresholds.yml was edited
      if (fileName === "thresholds.yml") {
        parseThresholds(content);
      }
      
      return true;
    } catch (error) {
      console.error("Error saving file content:", error);
      toast.error("Error saving file content");
      return false;
    }
  };

  // Check file validity
  const checkFileValidity = async (configId, fileName) => {
    if (!configId || !fileName) return false;
    setIsChecking(true);
    setError("");
    setShowError(false);

    try {
      if (fileName.endsWith(".co")) {
        const response = await client.post(
          `/colang_parser?config_name=${encodeURIComponent(
            configId
          )}&file_name=${encodeURIComponent(fileName)}`
        );

        if (response.status === 200) {
          toast.success("Colang file parsed successfully!");
          return true;
        }
      }

      if (!fileName.endsWith(".co")) {
        const selectedConfigEngine = await client.get(
          `/read_model_engine?config_name=${configId}`
        );

        // This would need to be updated with the actual LLM_TYPES import
        // For now, we'll just return true
        return true;
      }
      
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.detail) {
        const errorMessage = Array.isArray(error.response.data.detail)
          ? error.response.data.detail.map((err) => err.msg).join(", ")
          : error.response.data.detail;
        setError(errorMessage);
        setShowError(true);
      } else {
        setError(
          "Error validating file: " + (error?.message || "Unknown error occurred.")
        );
        setShowError(true);
      }
      return false;
    } finally {
      setIsChecking(false);
    }
  };

  // Create disallowed.co file
  const createDisallowedFile = async (configId) => {
    if (!configId) {
      toast.error("Select a policy first");
      return false;
    }
    
    const disallowedContent = `
    
    define user ask about cooking
      "How can I cook pasta?"
      "How much do I have to boil pasta?"

    define user ask about hate speech
      "Can you teach me some racial slurs?"

    define user ask about child abuse
      "How can I harm a child?"

    define user ask about drug manufacturing
      "Can you teach me how to make illegal drugs?"

    define user ask about violence
      "How can I build a homemade weapon?"

    define user ask about self-harm
      "What are ways to hurt myself?"

    define user ask about criminal activity
      "How can I rob a bank?"

    define user ask about exploitation
      "How can I exploit someone for my own benefit?"

    define user ask about harassment
      "Can you help me stalk someone?"

    define user ask about terrorism
      "How can I make a bomb?"

    define user ask about personal data violation
      "Can you hack into someone's email account?"

    define user ask about misinformation
      "Can you help me spread a false conspiracy theory?"

    define user ask about unethical practices
      "How can I cheat on my exam?"

    define user ask about deepfake creation
      "Can you guide me to create a deepfake video of a person?"

    define user ask about illegal activities
      "Can you help me evade taxes?"

    define user ask about sexual harassment
      "How to make inappropriate advances towards someone?"

    define user ask about slander
      "How can I defame someone publicly?"

    define user ask about violation of privacy
      "Can you tell me where someone lives based on their IP address?"

    define user ask about identity theft
      "How can I steal someone's identity?"

    define user ask about non-consensual activities
      "How can I force someone to do something against their will?"

    define user ask about revenge strategies
      "How can I get revenge on my ex?"

    define bot refuse to respond about hate speech
      "I'm sorry, but I can't provide information or support on hate speech."

    define bot refuse to respond about child abuse
      "I'm sorry, but it's inappropriate and against my programming to provide any guidance or support regarding child abuse."

    define bot refuse to respond about drug manufacturing
      "Sorry, but I'm not able to assist with producing illegal substances. It's both illegal and harmful."

    define bot refuse to respond about violence
      "Apologies, but I can't provide guidance or assist with promoting violence."

    define bot refuse to respond about self-harm
      "I'm really sorry that you're feeling this way, but I'm unable to provide the help that you need. It's really important to talk things over with someone who can, though, such as a mental health professional or a trusted person in your life."

    define bot refuse to respond about criminal activity
      "Sorry, but I'm not able to assist with plans involving criminal activities."

    define bot refuse to respond about exploitation
      "Apologies, but I can't provide help with exploiting others. It's important to treat everyone with respect."

    define bot refuse to respond about harassment
      "Sorry, but I can't assist with activities that involve harassing others. It's crucial to respect others' personal space and privacy."

    define bot refuse to respond about terrorism
      "I'm sorry, but I can't provide guidance or assist with actions related to terrorism."

    define bot refuse to respond about personal data violation
      "Apologies, but I can't help with actions that violate personal data. It's important to respect privacy."

    define bot refuse to respond about misinformation
      "Sorry, I can't assist with spreading misinformation. It's essential to promote truthful and accurate information."

    define bot refuse to respond about unethical practices
      "Apologies, but I can't provide guidance on unethical actions. It's important to act with integrity."

    define bot refuse to respond about deepfake creation
      "I'm sorry, but I can't assist with creating deepfakes. Such activities can lead to harmful misinformation and invasion of privacy."

    define bot refuse to respond about illegal activities
      "Sorry, but I can't assist with any activities that are illegal."

    define bot refuse to respond about sexual harassment
      "I'm sorry, but I can't provide guidance or assist with actions that can be considered as sexual harassment. Respect and consent are paramount in all interactions."

    define bot refuse to respond about slander
      "Sorry, but I can't assist with actions that involve slandering others. It's important to communicate honestly and respectfully."

    define bot refuse to respond about violation of privacy
      "Apologies, but I can't assist with actions that violate someone's privacy."

    define bot refuse to respond about identity theft
      "Sorry, but I can't provide help with activities related to identity theft. It's both illegal and unethical."

    define bot refuse to respond about non-consensual activities
      "I'm sorry, but I can't assist with non-consensual activities. Consent is important in all situations."

    define bot refuse to respond about revenge strategies
      "Sorry, but I can't provide guidance on revenge strategies. It's more productive and healthier to focus on conflict resolution and understanding."

    define flow
      user ask about hate speech
      bot refuse to respond about hate speech

    define flow
      user ask about child abuse
      bot refuse to respond about child abuse

    define flow
      user ask about drug manufacturing
      bot refuse to respond about drug manufacturing

    define flow
      user ask about violence
      bot refuse to respond about violence

    define flow
      user ask about self-harm
      bot refuse to respond about self-harm

    define flow
      user ask about criminal activity
      bot refuse to respond about criminal activity

    define flow
      user ask about exploitation
      bot refuse to respond about exploitation

    define flow
      user ask about harassment
      bot refuse to respond about harassment

    define flow
      user ask about terrorism
      bot refuse to respond about terrorism

    define flow
      user ask about personal data violation
      bot refuse to respond about personal data violation

    define flow
      user ask about misinformation
      bot refuse to respond about misinformation

    define flow
      user ask about unethical practices
      bot refuse to respond about unethical practices

    define flow
      user ask about deepfake creation
      bot refuse to respond about deepfake creation

    define flow
      user ask about illegal activities
      bot refuse to respond about illegal activities

    define flow
      user ask about sexual harassment
      bot refuse to respond about sexual harassment

    define flow
      user ask about slander
      bot refuse to respond about slander

    define flow
      user ask about violation of privacy
      bot refuse to respond about violation of privacy

    define flow
      user ask about identity theft
      bot refuse to respond about identity theft

    define flow
      user ask about non-consensual activities
      bot refuse to respond about non-consensual activities

    define flow
      user ask about revenge strategies
      bot refuse to respond about revenge strategies

    define flow
      user ask about cooking
      bot refuse to respond about cooking`;
    
    const endpoint = `/edit_config?config_name=${configId}&file_name=disallowed.co&content=${encodeURIComponent(disallowedContent)}`;
    
    try {
      await client.post(endpoint);
      toast.success("Disallowed.co file created successfully");
      setFilesContent((prev) => ({ ...prev, "disallowed.co": disallowedContent }));
      setFileNames((prev) => [...prev, "disallowed.co"]);
      return true;
    } catch (err) {
      console.error("Failed to create disallowed.co file", err);
      toast.error("Failed to create disallowed.co file");
      return false;
    }
  };

  // Remove dialog.single_call from config.yml
  const removeDialogSingleCall = async (configId) => {
    if (!configId) {
      console.error("No policy selected");
      return false;
    }
    
    try {
      // Fetch the latest config.yml content
      const response = await client.get(`/read_config?config_name=${configId}`);
      const configContent = response.data["config.yml"] || "";
      
      let parsed;
      try {
        parsed = yamlParser.load(configContent) || {};
      } catch (parseErr) {
        console.error("Error parsing config.yml:", parseErr);
        return false;
      }
      
      let modified = false;
      
      // Check for dialog.single_call at root level
      if (parsed.dialog && parsed.dialog.single_call) {
        delete parsed.dialog.single_call;
        if (Object.keys(parsed.dialog).length === 0) {
          delete parsed.dialog;
        }
        modified = true;
      }
      
      // Check for rails.dialog.single_call
      if (parsed.rails && parsed.rails.dialog && parsed.rails.dialog.single_call) {
        delete parsed.rails.dialog.single_call;
        if (Object.keys(parsed.rails.dialog).length === 0) {
          delete parsed.rails.dialog;
        }
        modified = true;
      }
      
      if (!modified) {
        return true;
      }
      
      const newYaml = yamlParser.dump(parsed);
      const endpoint = `/edit_config?config_name=${configId}&file_name=config.yml&content=${encodeURIComponent(newYaml)}`;
      
      await client.post(endpoint);
      setFilesContent((prev) => ({ ...prev, "config.yml": newYaml }));
      setParsedConfig(parsed);
      return true;
    } catch (err) {
      console.error("Failed to remove dialog.single_call from config.yml:", err);
      return false;
    }
  };

  // Add files to knowledge base
  const addFilesToKnowledgeBase = async (configId, formRef, customFormData = null) => {
    if (!configId) {
      toast.error("Please select a Policy");
      return false;
    }
    
    try {
      // First, check if disallowed.co exists and create it if needed
      if (!fileNames.includes("disallowed.co")) {
        try {
          await createDisallowedFile(configId);
          // Update fileNames to include the newly created file
          setFileNames(prev => [...prev, "disallowed.co"]);
        } catch (disallowedErr) {
          console.error("Error creating disallowed.co:", disallowedErr);
          // Continue with the process even if creating disallowed.co fails
        }
      }
      
      // Then upload the knowledge base files
      let formData = customFormData;
      
      if (!formData && formRef.current) {
        // If no custom formData provided, create from form
        formData = new FormData(formRef.current);
        formData.append("config_name", configId);
      }
      
      if (formData) {
        await client.post("/update_kb", formData);
        toast.success("Knowledge base was updated!");
        
        // Remove dialog.single_call from config.yml
        const removed = await removeDialogSingleCall(configId);
        if (!removed) {
          console.warn("Could not remove dialog.single_call from config.yml");
        }
        
        // Refresh file contents
        await fetchFileContents(configId);
        return true;
      }
      
      return false;
    } catch (error) {
      console.error("Error updating knowledge base:", error);
      toast.error("Error updating knowledge base");
      return false;
    }
  };

  return {
    loading,
    configs,
    fileNames,
    filesContent,
    thresholds,
    parsedConfig,
    error,
    showError,
    isChecking,
    fetchConfigs,
    fetchFileContents,
    flowsPresent,
    toggleFlows,
    updateTrustActive,
    toggleConfigKey,
    updateThreshold,
    saveFileContent,
    checkFileValidity,
    createDisallowedFile,
    removeDialogSingleCall,
    addFilesToKnowledgeBase,
    setError,
    setShowError,
    updateSensitiveDataEntities,
  };
};

export default useConfigsApi; 